.searchBarTitle {
  position: relative;
  color: rgba(0, 0, 0, .87);
  line-height: 2;
  letter-spacing: 1;
  font-weight: 900;
  width: calc((24 + 1) * 12px);
  font-size: 24px;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 2s steps(12) forwards;
   
  @media screen(md) {
    width: calc((30 + 1) * 12px);
    font-size: 30px;
  }
}

.searchBarTitle::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 2px;
  height: 36px;
  background-color: rgba(0, 0, 0, .63);
  opacity: 0;
  animation: caret 1s linear 3 forwards;
}

.searchBarSlogan {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: rgba(0, 0, 0, .87);
  opacity: 0;
  animation: fading 1s ease-in-out 2s forwards;

  @media screen(md) {
    font-size: 18px;
  }
}


@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes fading {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}

@keyframes caret {
  50% {
    opacity: 1;
  }
}