.contentStyle h2 {
  margin: theme('spacing.6') 0;
  font-size: theme('fontSize.2xl');
  font-weight: 700;
  line-height: 1.5;
}

.contentStyle h3 {
  font-size: theme('fontSize.xl');
  margin: theme('spacing.4') 0;
  font-weight: 700;
  text-align: center;
}

.contentStyle h4 {
  font-size: theme('fontSize.lg');
  margin: theme('spacing.4') 0;
  font-weight: 500;
}

.contentStyle p {
  margin-bottom: theme('spacing.6');
  line-height: 1.75;
}

.contentStyle a {
  color: var(--primary-color);
  font-weight: 500;
  text-decoration: underline;

  &:hover {
    color: var(--secondary-color);
  }
}

.contentStyle ul {
  padding-left: theme('spacing.4');
  list-style: disc inside;
}

.contentStyle ol {
  padding-left: theme('spacing.4');
  list-style: decimal inside;
}

.contentStyle li {
  margin-bottom: theme('spacing.2');
  line-height: 1.5;
  font-weight: 500;
}

.contentStyle img {
  margin: auto;
  width: 100%;
  height: auto;
  object-fit: contain;

  @media screen(md) {
    width: 75%;
  }
}

.contentStyle figure {
  margin: theme('spacing.2') auto;
  text-align: center;
}

.contentStyle blockquote {
    margin: theme('spacing.4') 0;
    padding-left: theme('spacing.2');
    border-left: 4px solid theme('colors.gray.500');
    color: theme('colors.gray.500');
    font-style: italic;

    & p {
      margin-bottom: theme('spacing.2');
    }
}

.contentStyle figure > iframe {
  width: 288px!important;
  height: 162px!important;

  @media screen(md) {
    width: 560px!important;
    height: 315px!important;
  };
}

.contentStyle figure > blockquote {
  margin: theme('spacing.6') auto;
  padding: theme('spacing.6');
  border: none;
  background-color: rgba(43, 102, 177, .15);
  text-align: left;
  font-style: normal;
  color: rgba(0, 0, 0, .85);
  border-radius: 8px;

  & p {
    margin: 0;
    text-align: center;
    font-size: theme('fontSize.lg');
    line-height: 1.5;
    font-weight: 700;
  }

  & cite {
    text-align: left;
    font-style: normal;
  }
}